import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout footer>
    <h1 className="font-thin text-mobile-large md:text-desktop-large leading-large md:h-lead-15 pt-lead-1 px-lead-1 md:pt-0 md:pl-0 md:pb-0 pb-lead-1 md:mt-[-0.6rem] col-span-12">
      Page Not Found
    </h1>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
